import React, { createContext, useRef, useState, useEffect } from "react";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import down_icon from "../../assets/icons/down.png";

// ---- Data ----
import { currencies } from "../../assets/data/currencies";
import { lootboxes } from "../../assets/data/lootboxes";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import useCustomer from "../../services/wallet/useCustomer";
import customer_instance from "../../services/wallet/customer";
import { update_exchange_rate, create_customer, create_payment, add_destination_address, check_spend } from "../../services/api"
import useAttribution from "../../services/useAttribution";
import { add_thousand_separator } from "../../services/helper_functions";
import { trigger_event } from "../../services/analytics";
import { get_country } from "../../services/location";

// ---- Modals ----
import { useErrorPopUp } from "../../modals/error_pop_up";
import { useLoadingOverlay } from "../../modals/loading";

function Lootbox_Currency({ close, parameters, set_parameters }) {

    useEffect(()=>{
        trigger_event("open_purchase_modal", 0)
    },[]);

    // ---- Pre Calc Limits ----
    let [current_spend, set_current_spend] = useState(0);
    useEffect(()=>{
        (async function() {
            if (customer && customer.customer_id) {
                let { current_spend } = await check_spend(customer.customer_id, "ETH");
                set_current_spend(current_spend);
            }
        })();
    },[]);

    // ---- Error Modal ----
    let { throw_error } = useErrorPopUp();

    const { sources, add_source } = useAttribution();

    let token = useToken();
    let customer = useCustomer();
    let currency_container_ref = useRef(null);

    let lootbox = lootboxes[parameters.lootbox_index];

    let { open_loading_overlay, close_loading_overlay } = useLoadingOverlay();

    async function select_currency(currency_index) {

        currency_container_ref.current.style = "opacity: 0.25; pointer-events: none;";

        try {
            if (currency_container_ref.current && currency_index > 0) {

                open_loading_overlay();
                
                let currency = currencies[currency_index];

                let exchange_rates = await update_exchange_rate();
                let rate = exchange_rates.exchange_rates[currency.symbol];
                let hint_amount = lootbox.usd_threshold / rate;

                if (customer && customer.customer_id) {

                    // let { current_spend } = await check_spend(customer.customer_id, currency.symbol);
                    let usd_purchase_value = lootbox.usd_threshold;
                    if (current_spend + usd_purchase_value > 25000) {
                        let max_allowance = 25000 - current_spend;
                        let max_allowance_currency = max_allowance / rate;
                        let params = {
                            title: "Oh No!",
                            content: `It looks like you are trying to purchase more than our monthly limit of US$25,000 or equivalent cryptocurrency per customer. You have already spent ${add_thousand_separator(current_spend.toFixed(0))} this month. You can now only purchase for a maximum of US$${add_thousand_separator(max_allowance)} (${max_allowance_currency.toFixed(currency.sig_figs)} ${currency.symbol})`,
                            buttons: [
                                {
                                    title: "Okay"
                                }
                            ]
                        }
                        close_loading_overlay();
                        throw_error(params);
                        currency_container_ref.current.style = "opacity: 1; pointer-events: auto;";
                        return;
                    }

                    let { payin_address, payment_id, deadline_at } = await create_payment(customer.customer_id, hint_amount, currency_index);
                    if (token && token.wallet_address && !customer.destination_address) {
                        await add_destination_address(customer.customer_id, customer.secret_code, token.wallet_address);
                        customer_instance.add_destination_address(token.wallet_address);
                    }
                    close_loading_overlay();
                    set_parameters({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                }
                else {
                    customer_instance.init();
                    let { secret_code } = customer_instance.current
                    let { customer_id, payin_address, payment_id, deadline_at } = await create_customer(secret_code, hint_amount, currency_index, sources);
                    customer_instance.add_id(customer_id);
                    if (token && token.wallet_address) {
                        await add_destination_address(customer_id, secret_code, token.wallet_address);
                        customer_instance.add_destination_address(token.wallet_address);
                    }
                    close_loading_overlay();
                    set_parameters({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                }

                
            }
            else if (currency_container_ref.current) {
                
                let currency = currencies[currency_index];

                let exchange_rates = await update_exchange_rate();
                let rate = exchange_rates.exchange_rates[currency.symbol];
                let hint_amount = lootbox.usd_threshold / rate;

                if (!customer || !customer.customer_id) {
                    customer_instance.init();
                    let { secret_code } = customer_instance.current
                    let { customer_id } = await create_customer(secret_code, hint_amount, 0, sources);
                    customer_instance.add_id(customer_id);
                    if (token && token.wallet_address) {
                        await add_destination_address(customer_id, secret_code, token.wallet_address);
                        customer_instance.add_destination_address(token.wallet_address);
                    }
                }
                else {
                    // let { current_spend } = await check_spend(customer.customer_id, "USD");
                    if (current_spend + parseFloat(hint_amount) > 25000) {
                        let max_allowance = 25000 - current_spend;
                        let params = {
                            title: "Oh No!",
                            content: `It looks like you are trying to purchase more than our monthly credit card limit of US$25,000. You have already spent ${add_thousand_separator(current_spend.toFixed(0))} this month. You can now only purchase for a maximum of US$${add_thousand_separator(max_allowance)}`,
                            buttons: [
                                {
                                    title: "Okay"
                                }
                            ]
                        }
                        throw_error(params);
                        currency_container_ref.current.style = "opacity: 1; pointer-events: auto;";
                        return;
                    }
                }

                // set_parameters({type: "pay_card", hint_amount});

                // let country = await get_country();
                // if (country == "US") {
                //     set_parameters({type: "pay_wert", hint_amount});
                // }
                // else {
                //     set_parameters({type: "pay_card", hint_amount});
                // }
                // set_parameters({type: "pay_card", hint_amount});
                // set_parameters({type: "pay_wert", hint_amount});
                set_parameters({type: "pay_tweed", hint_amount});
            }
        } catch (error) {
            close_loading_overlay();
            // console.log(error);
        }

        currency_container_ref.current.style = "opacity: 1; pointer-events: auto;";
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Select Currency</h2>
                        <img className="back_icon" src={down_icon} onClick={close}/>
                    </div>
                    <div className="medium_spacer"></div>
                    <p className="error_text_content">Select your preferred payment currency from the list below</p>
                    <div className="medium_spacer"></div>
                </div>

                <div className="lootbox_list_container" ref={currency_container_ref}>
                    {
                        currencies.map((currency, index) => (

                            <>
                                {
                                    // parameters.lootbox_index >= 3 && index == 0 ?
                                    // index == 0 ?
                                    // <></> :
                                    <div key={index} className="language_item gray_button_hover" onClick={()=>select_currency(index)}>
                                        <img className="language_icon" src={currency.icon}/>
                                        <div className="language_amount_label">{currency.name}</div>
                                        <img className="language_arrow_right" src={right_icon}/>
                                    </div>
                                }
                            </>

                            
                        ))
                    }
                </div>
                {/* <div className="small_spacer"></div> */}
            </div>
        </>
    );
}

export default Lootbox_Currency;